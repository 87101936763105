import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Gynecology() {
  const title = "Gynecology / Mammary gland surgery";
  const subTitle = "婦人科・乳腺外科";
  const overview = [
    "患者様は女性が中心となる診療科目です",
    <br />,
    "女性医師の開業は大きなメリットとなります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　2,000万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、内診台、X線撮影装置",
    <br />,
    "DICOM（ダイコム）画像を見られる高精細モニターとPACS、超音波診断装置、コルポスコープ など",
  ];
  const point = "ポイント";
  const pointText = [
    "・女性医師ならさらにアピール",
    <br />,
    "・女性が1人で通いやすい物件の選定",
    <br />,
    <br />,
    "女性医師であればホームページや広告で積極的にアピールをし、",
    <br />,
    "SEO（検索エンジン対策）にて女性といったキーワードを使用してくことが重要",
    <br />,
    "アメニティーを充実させるスペースを確保できる物件を選ぶように心がける",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
